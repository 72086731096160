import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';


import feelingIconURL from './feeling/feeling.png';
import feelingInsetIconURL from './feeling/feeling-small.png';

import watsonIconURL from './watson/watson.png';
import watsonInsetIconURL from './watson/watson-small.png';

import watson_nlcIconURL from './watson_nlc/watson_nlc.png';
import watson_nlcInsetIconURL from './watson_nlc/watson_nlc-small.png';

import ml2scratchIconURL from './ml2scratch/ml2scratch.png';
import ml2scratchInsetIconURL from './ml2scratch/ml2scratch-small.png';

import facemesh2scratchIconURL from './facemesh2scratch/facemesh2scratch.png';
import facemesh2scratchInsetIconURL from './facemesh2scratch/facemesh2scratch-small.png';


import posenet2scratchIconURL from './posenet2scratch/posenet2scratch.png';
import posenet2scratchInsetIconURL from './posenet2scratch/posenet2scratch-small.png';

import speech2scratchIconURL from './speech2scratch/speech2scratch.png';
import speech2scratchInsetIconURL from './speech2scratch/speech2scratch-small.png';

import ic2scratchIconURL from './ic2scratch/ic2scratch.png';
import ic2scratchInsetIconURL from './ic2scratch/ic2scratch-small.png';

import tm2scratchIconURL from './tm2scratch/tm2scratch.png';
import tm2scratchInsetIconURL from './tm2scratch/tm2scratch-small.png';

import LanguageIconURL from './Language/Language.png';
import LanguageInsetIconURL from './Language/Language-small.png';

import weatherURL from './weather/weather.png';
import weatherIconURL from './weather/weather-small.png';



import colourIconURL from './colour/colour.png';
import colourInsetIconURL from './colour/colour-small.png';

import wikiIconURL from './wiki/wiki.png';
import wikiInsetIconURL from './wiki/wiki-small.png';

import handpose2scratchIconURL from './handpose2scratch/handpose2scratch.png';
import handpose2scratchInsetIconURL from './handpose2scratch/handpose2scratch-small.png';

import facefeatureIconURL from './facefeature/facefeature.png';
import facefeatureInsetIconURL from './facefeature/facefeature-small.png';

import ocrIconURL from './ocr/ocr.png';
import ocrInsetIconURL from './ocr/ocr-small.png';

import sheetIconURL from './sheets/sheets.png';
import sheetInsetIconURL from './sheets/sheets-small.png';

import rxIconURL from './regex/rx.png';
import rxInsetIconURL from './regex/rx-small.png';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';




export default [
    
    
    {
        name: 'SR w Scratch',
        extensionId: 'speech2scratch',
       
        iconURL: speech2scratchIconURL,
        insetIconURL: speech2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Speech Recognition '
                description='AI extn blocks | Speech Recognition'
                id='gui.extension.speech2scratchblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'SA w Scratch',
        extensionId: 'sentiment',
    
        iconURL: feelingIconURL,
        insetIconURL: feelingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Sentimental Analysis'
                description='AI extn blocks | Sentimental Analysis'
                id='gui.extension.feelingblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'ML w Scratch',
        extensionId: 'ml2scratch',
    
        iconURL: ml2scratchIconURL,
        insetIconURL: ml2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Image classification with Machine learning '
                description='AI extn blocks | Image classification with Machine learning'
                id='gui.extension.ml2scratchblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'FM w Scratch',
        extensionId: 'facemesh2scratch',
        
        iconURL: facemesh2scratchIconURL,
        insetIconURL: facemesh2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Face mesh & feature recognition'
                description='AI extn blocks | Face mesh & feature recognition'
                id='gui.extension.facemesh2scratch.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'LR w Scratch',
        extensionId: 'Language',
        collaborator: 'robotix',
        iconURL: LanguageIconURL,
        insetIconURL: LanguageInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks| Language Recognition and identification'
                description='AI extn blocks | Language Recognition and identification'
                id='gui.extension.Languageblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
 {
        name: 'WB w Scratch',
        extensionId: 'weather',
	 collaborator: 'robotix',

        iconURL: weatherURL,
        insetIconURL: weatherIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks| Realtime Weather Data'
                description='AI extn blocks| Realtime Weather Data'
                id='gui.extension.weatherblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'TM w Scratch',
        extensionId: 'tm2scratch',
        iconURL: tm2scratchIconURL,
        insetIconURL: tm2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Teachable Machines'
                description='AI extn blocks | Teachable Machines'
                id='gui.extension.tm2scratchblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'IC w Scratch',
        extensionId: 'ic2scratch',
        
        iconURL: ic2scratchIconURL,
        insetIconURL: ic2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Image classifier '
                description='AI extn blocks | Image classifier'
                id='gui.extension.ic2scratchblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    
    {
        name: 'PD w Scratch',
        extensionId: 'posenet2scratch',
       
        iconURL: posenet2scratchIconURL,
        insetIconURL: posenet2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Human pose detection '
                description='AI extn blocks | Human pose detection'
                id='gui.extension.posenet2scratchblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'FI w Scratch',
        extensionId: 'handpose2scratch',
        
        iconURL: handpose2scratchIconURL,
        insetIconURL: handpose2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Human Finger recognition'
                description='AI extn blocks | Human Finger recognition'
                id='gui.extension.handpose2scratchblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false,
        helpLink: 'https://champierre.github.io/handpose2scratch/'
    },
   
    
    {
        name: 'TC w scratch',
        extensionId: 'text',
     
        iconURL: watson_nlcIconURL,
        insetIconURL: watson_nlcInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Text classification with Machine learning'
                description='AI extn blocks | Text classification with Machine learning'
                id='gui.extension.watson_nlcblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'CV pro w Scratch',
        extensionId: 'vision',

        iconURL: watsonIconURL,
        insetIconURL: watsonInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Image training with Machine learning'
                description='AI extn blocks | Image training with Machine learning'
                id='gui.extension.watsonblocks.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
	
     {
        name: 'OCR w Scratch',
        extensionId: 'ocr',
        iconURL: ocrIconURL,
        insetIconURL: ocrInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Printed text recognition.'
                description='AI extn blocks | Printed text recognition.'
                id='gui.extension.ocr.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
     
    },
       {
        name: 'FaceReg w Scratch',
        extensionId: 'facefeature',
        iconURL: facefeatureIconURL,
        insetIconURL: facefeatureInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Face feature recognition.'
                description='AI extn blocks | Face feature recognition.'
                id='gui.extension.facefeature.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
     
    },
     {
        name: 'Wiki w Scratch',
        extensionId: 'wiki',
        iconURL: wikiIconURL,
        insetIconURL: wikiInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Wiki connect.'
                description='AI extn blocks | Wiki connect.'
                id='gui.extension.wiki.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    }, {
        name: 'Colour Detection w Scratch',
        extensionId: 'colour',
        //collaborator: 'robotix',
        iconURL: colourIconURL,
        insetIconURL: colourInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Camera Vision.'
                description='AI extn blocks | Camera Vision.'
                id='gui.extension.colour.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
     {
        name: 'Data Science w Scratch',
        extensionId: 'sheets',
        //collaborator: 'sheet',
        iconURL: sheetIconURL,
        insetIconURL: sheetInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Access Google Sheets'
                description='AI extn blocks | Access Google Sheets'
                id='gui.extension.sheets.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    /* {
        name: 'RegEx w Scratch',
        extensionId: 'regex',
        //collaborator: ' ',
        iconURL: rxIconURL,
        insetIconURL: rxInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage='AI extn blocks | Natural Speech Interaction.'
                description='AI extn blocks | Natural Speech Interaction.'
                id='gui.extension.regex.description'
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
        

    },*/
    {
        name: 'micro:bit',
        extensionId: 'microbit',
        collaborator: 'micro:bit',
        iconURL: microbitIconURL,
        insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/microbit'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        collaborator: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    }
    ];
