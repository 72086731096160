exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes mic-indicator_popIn_11qHK {\n    from {transform: scale(0.5)}\n    to {transform: scale(1)}\n}\n\n@keyframes mic-indicator_popIn_11qHK {\n    from {transform: scale(0.5)}\n    to {transform: scale(1)}\n}\n\n.mic-indicator_mic-img_3cs6_ {\n    margin: 10px;\n    transform-origin: center;\n    -webkit-animation: mic-indicator_popIn_11qHK 0.1s ease-in-out;\n            animation: mic-indicator_popIn_11qHK 0.1s ease-in-out;\n}\n", ""]);

// exports
exports.locals = {
	"mic-img": "mic-indicator_mic-img_3cs6_",
	"micImg": "mic-indicator_mic-img_3cs6_",
	"popIn": "mic-indicator_popIn_11qHK"
};