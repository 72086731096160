exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .author-info_author-info__Auzh {\n    color: hsla(0, 100%, 100%, 1);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    cursor: default;\n} .author-info_avatar_1EtPD {\n    margin-right: .5625rem;\n} .author-info_project-title_3abqH {\n    max-width: 12rem;\n    display: block;\n    overflow: hidden;\n    font-size: 0.875rem;\n    font-weight: bold;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n} .author-info_username-line_30Olt {\n    max-width: 12rem;\n    font-size: 0.75rem;\n    display: block;\n    overflow: hidden;\n    font-weight: normal;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n} .author-info_username_3V5C- {\n    font-weight: bold;\n}\n", ""]);

// exports
exports.locals = {
	"author-info": "author-info_author-info__Auzh",
	"authorInfo": "author-info_author-info__Auzh",
	"avatar": "author-info_avatar_1EtPD",
	"project-title": "author-info_project-title_3abqH",
	"projectTitle": "author-info_project-title_3abqH",
	"username-line": "author-info_username-line_30Olt",
	"usernameLine": "author-info_username-line_30Olt",
	"username": "author-info_username_3V5C-"
};